import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Hi, I'm Cameron Zaas`}</h1>
    <p>{`Staying focused on the end user's problems as well as aligned with business goals; I work with empathy, optimism, and strive with others to solve problems.`}</p>
    <p>{`With user experience always at the forefront, I'm proficient in JavaScript, as well as semantic HTML, and scalable CSS/SCSS/LESS best practices.`}</p>
    <h2>{`Projects`}</h2>
    <p>{`I work on the Front End developing dynamic responsive applications and web sites. `}<Link to="/projects" mdxType="Link">{`See some of my work here`}</Link>{`.`}</p>
    <p>{`You can find my most recent work on my `}<a href="https://github.com/czaas" target="_blank">{`github profile`}</a>{`.`}</p>
    <h2>{`About me`}</h2>
    <p>{`A little `}<Link to="/about" mdxType="Link">{`about me`}</Link>{` when I'm not working on web stuff.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      